import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { PageHeader } from '../components/PageHeader';
import ProjectList from '../components/Projects/ProjectList';
import { decodeEntities } from '../utils/helpers';

const ProjectIndexPage = (props) => {
  const { data, pageContext, location, path } = props;
  const {wordpressPost: page = [], allWordpressWpProjects, categoryFilter, categories, wordpressWpSettings } = data;
  const { wordpressUrl } = wordpressWpSettings
  const { title, yoast, acf } = page ? page : { title: null, yoast: {}, acf: {} }
  const { siteTitle } = wordpressWpSettings;
  const { edges: posts } = allWordpressWpProjects

  return (
    <Layout location={location} wordpressUrl={wordpressUrl}>
      <SEO
        title={`${
          yoast.metaTitle ?
          yoast.metaTitle :
          `Projects | ${decodeEntities(wordpressWpSettings.title)}`
        }`}
        desc={yoast.metaDescription}
        yoast={yoast}
        location={location}
      />
      <PageHeader headerTitle="Projects" location={location} />
      <ProjectList
        posts={posts}
        title="Latest projects"
        pageContext={pageContext}
        siteMetadata={wordpressWpSettings}
        categories={categories.edges}
        pathPrefix={path}
      />
    </Layout>
  )
}

export default ProjectIndexPage

export const pageQuery = graphql`
  query ProjectIndexQuery($limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title,
      wordpressUrl
      siteUrl
    }
    wordpressPost: wordpressPage(slug: {eq: "projects"}) {
      title
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
        meta_robots_noindex
        meta_robots_nofollow
      }
    }
    wordpressCategory: wordpressWpProjectCategory(slug: {eq: "uncategorised"}) {
      name
      slug
      path
      description
      acf {
        featureImage {
          source_url
        }
        categoryHeaderImage {
          source_url
        }
        title
        categoryPageDescription
      }
    }
    allWordpressWpProjects(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProjectListFields
        }
      }
    }
    categories: allWordpressWpProjectCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          path
          count
        }
      }
    }
  }
`
